import {Controller} from "@hotwired/stimulus"
import {passwordStrength} from 'check-password-strength'


export default class extends Controller {

  check(e) {
    const password = document.querySelector('#form-password').value
    const strengthResult = passwordStrength(password)
    const strengthId = strengthResult.id
    const contains = strengthResult.contains

    if (password === ''){
      return document.querySelector('#password-strength').innerHTML = ''
    }

    const mustContains = {
      'lowercase': 'minuscules nécessaires',
      'uppercase': 'majuscules nécessaires',
      'number': 'chiffres nécessaires',
      'symbol': 'caractères spéciaux nécessaires',
    }

    const errors = []

    Object.keys(mustContains).forEach(key => {
      if (!contains.includes(key)) {
        errors.push(mustContains[key])
      }
    })

    if (strengthId < 3 && errors.length === 0) {
      errors.push('le mot de passe n\'est pas assez long')
    }

    this.displayStrength(strengthId, errors)
  }

  displayStrength(strengthId, errors) {
    const container = document.querySelector('#password-strength')
    container.innerHTML = ''

    let bsClass = 'bg-success'

    if (strengthId < 1) {
      bsClass = 'bg-danger'
    } else if (strengthId < 3) {
      bsClass = 'bg-warning'
    }

    for (let i = 0; i < 4; i++) {
      let div = document.createElement('div')
      div.style.height = '5px'
      div.style.borderRight = '5px solid #f4f6fa'
      div.classList.add(
        'col-3',
        i <= strengthId ? bsClass : 'bg-light'
      )
      container.appendChild(div)
    }

    if (errors.length) {
      const message = document.createElement('div')
      message.classList.add(
        'px-0',
        'mt-1',
        'fs-1',
        'fw-semibold'
      )

      const items = errors.map((e) => `<li>${e}</li>`).join('')

      message.innerHTML = `Votre mot de passe n'est pas conforme à nos critères de sécurité : <ul>${items}</ul>`

      container.appendChild(message)
    }
  }
}
