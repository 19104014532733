import {Controller} from "@hotwired/stimulus"
import { passwordStrength } from 'check-password-strength'
import generator from 'generate-password'

const generatePassword = () => {
  let password

  do {
    password = generator.generate({
      length: 16,
      numbers: true,
      uppercase: true,
      symbols: true,
      strict: true,
    })

  } while (passwordStrength(password).value !== 'Strong')

  return password
}

export default class extends Controller {
  generate(event) {
    const button = event.target
    const input = document.querySelector(button.getAttribute('data-target'))

    input.value = generatePassword()
    input.focus()
    input.type = 'text'
    input.select()
  }
}
