import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    const button = event.target
    const input = document.querySelector(button.getAttribute('data-target'))
    let type, currentIcon, newIcon

    if (input.type === 'text') {
        type = 'password'
        currentIcon = button.getAttribute('data-iconshow')
        newIcon = button.getAttribute('data-iconhide')
    } else {
        type = 'text'
        currentIcon = button.getAttribute('data-iconhide')
        newIcon = button.getAttribute('data-iconshow')
    }

    button.classList.replace(currentIcon, newIcon)
    input.type = type
  }
}
