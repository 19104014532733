import {application} from "controllers/application"
//import {eagerLoadControllersFrom} from "@hotwired/stimulus-loading"
import PasswordToggler from "./password_toggler_controller"
import PasswordGenerator from "./password_generator_controller"
import FileManager from "./file_manager_controller"
import PasswordChecker from './password_checker_controller'
import { Popover } from 'bootstrap'

application.register("password_toggler", PasswordToggler)
application.register("password_generator", PasswordGenerator)
application.register("file_manager", FileManager)
application.register("password_checker", PasswordChecker)

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))