import {Controller} from "@hotwired/stimulus"
import {passwordStrength} from "check-password-strength";

export default class extends Controller {
  constructor() {
    super()

    this.listContainer = document.querySelector('#files-list')
    this.fileField = document.querySelector('#project_files_input')
    this.hiddenFileField = document.querySelector('#project_files')
    this.fileManager = document.querySelector('#file-manager')
    this.errorContainer = document.querySelector('#file-manager-error')
    this.maxFiles = parseInt(this.fileManager.getAttribute('data-maxfiles'))
    this.maxSize = parseInt(this.fileManager.getAttribute('data-maxsize'))
    this.form = this.fileManager.querySelector('form')
    this.error(null)
    this.attachEventListeners()
  }

  attachEventListeners(){
    this.form.addEventListener('submit', (e)=> {
      this.handleFormSubmit(e)
    })
  }

  error(message) {
    if (message === null) {
      this.errorContainer.style.display = 'none'
    } else {
      this.errorContainer.innerHTML = message
      this.errorContainer.style.display = 'block'
    }
  }

  change() {
    let fieldFiles = this.fileField.files
    let filesToUpload = this.hiddenFileField.files
    let totalFiles = fieldFiles.length + filesToUpload.length;

    let error = ''
    let filesInError = []

    if (totalFiles > this.maxFiles) {
        error += `Vous dépassez la limite maximum de ${this.maxFiles} fichiers`;
    }

    Array.from(fieldFiles).forEach(file => {
      if (file.size > this.maxSize) {
        filesInError.push(`Le fichier ${file.name} dépasse la taille autorisée`);
      }
    })

    if (filesInError.length > 0) {
      error += `<ul>`

      filesInError.forEach((f) => {
          error += `<li style="list-style:none">${f}</li>`
      })

      error += `</ul>`
    }


    if (error !== '') {
      return this.error(error)
    }

    const files = Array
      .from(filesToUpload)
      .concat(Array.from(fieldFiles))
    ;

    this.refresh(files)
    this.fileField.value = ''
  }

  refresh(files) {
    this.error(null)

    let list = new DataTransfer();
    this.listContainer.innerHTML = ''

    Array.from(files).forEach(file => {
      list.items.add(file);

      const id = list.items.length - 1
      const line = `
        <li class="list-group-item d-flex justify-content-between align-items-center">
          ${file.name}
          <button data-id="${id}" class="btn btn-lg btn-outline-danger px-2 border-0">
            <i class="iconoir-delete-circle" data-id="${id}"></i>
          </button>
        </li>
      `

      this.listContainer.innerHTML += line;
    });

    this.listContainer.querySelectorAll('button').forEach((item) => {
      item.addEventListener('click', (event) => {
        event.preventDefault()
        const key = parseInt(event.target.getAttribute('data-id'))
        this.removeFile(key);
      })
    });

    this.hiddenFileField.files = list.files;
  }

  removeFile(key) {
    const files = Array.from(this.hiddenFileField.files)
    files.splice(key, 1)
    this.refresh(files)
  }

  handleFormSubmit(e) {
    const password = document.querySelector('#form-password').value
    const isStrong = passwordStrength(password).id === 3

    e.preventDefault()

    if (this.hiddenFileField.files.length < 1){
      e.stopPropagation()
      this.error("Il faut séléctionner au moins un fichier")
      return false
    }

    if (password !== '' && !isStrong){
      e.stopPropagation()
      this.error("Le mot de passe n'est assez fort")
      return false
    }

    const form = this.form
    const progressBarWrapper = form.querySelector('#progress-bar-wrapper')
    const progressBar = progressBarWrapper.querySelector('.progress-bar')
    const progressWrapper = form.querySelector('#progress-wrapper')
    const button = form.querySelector('#submit')

    progressWrapper.classList.toggle('d-none', false)
    progressBarWrapper.classList.toggle('d-none', false)

    form.querySelectorAll('.form-submit-toggle').forEach((item) => {
      item.style.opacity = 0.3
    })

    const onProgress = (e, xhr) => {
      if (e.lengthComputable) {
        const value = e.loaded / e.total * 100
        const width = Math.min(value, 95)

        progressBar.style.width = `${width}%`

        button.value = "Envoi en cours…"

        if (value > 99) {
          button.value = "Analyse en cours…"
        }
      }
    }

    const onFinish = (e, xhr) => {
      progressBar.style.width = `100%`
      button.value = "Encore un instant…"

      const json = JSON.parse(xhr.responseText)

      if (json.status === 'OK') {
        document.location.href = json.url
      } else {
        this.refresh([])
        document.location.reload()
      }
    }
    this.doRequest(form.getAttribute('action'), this.createFormData(), onProgress, onFinish)
  }

  createFormData (){
    let data = new FormData()
    let fields = this.form.querySelectorAll('input:not([type=file])')

    fields.forEach((item) => {
      data.append(item.getAttribute('name'), item.value)
    })

    Array.from(this.hiddenFileField.files).forEach((item, key) => {
      data.append(this.hiddenFileField.getAttribute('name'), item, item.name)
    })

    return data
  }
  doRequest (url, data, onProgress, onFinish) {
    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener('progress', (e) => { onProgress(e, xhr) })
    xhr.addEventListener('loadend', (e) => { onFinish(e, xhr) })

    xhr.open('POST', url, true)
    xhr.send(data)
  }
}
